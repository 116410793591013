import { format } from 'date-fns'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'next-i18next'
import IconWrapper from '~/core/ui/IconWrapper'
import { TypographyText } from '~/core/ui/Text'
import { defaultFormatDate, timeFormatDate } from '~/core/utilities/format-date'
import {
  InterviewDetailType,
  InterviewType
} from '~/lib/features/calendar/types'
import { changeTimezone } from '~/lib/features/calendar/utilities/helper-schedule-interview'
import useBoundStore from '~/lib/store'
import { isFeedbackDue } from '~/components/Calendar/InterviewCalendarView'
import { Button } from '~/core/ui/Button'
import If from '~/core/ui/If'
import {
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger
} from '~/core/ui/Popover'
import { EditModalProps } from '~/lib/features/calendar/hooks/use-schedule-interview-calendar'
import CalendarPopupDetail from '~/components/Calendar/CalendarPopupDetail'
import useInterviewStore from '~/lib/features/interviews/store'
import configuration from '~/configuration'
import Link from 'next/link'
import useDetectCompanyWithKind from '~/lib/hooks/use-detect-company-with-kind'
import { AGENCY_TENANT } from '~/core/constants/enum'
import { Tooltip } from '~/core/ui/Tooltip'

function MyInterviewItemView({
  interview,
  setEditModal,
  interviewsListControl,
  action
}: {
  interview: InterviewDetailType
  setEditModal: Dispatch<SetStateAction<EditModalProps>>
  interviewsListControl: any
  action: any
}) {
  const { t } = useTranslation()
  const { user } = useBoundStore()
  const [openDetail, setOpenDetail] = useState(false)
  const { isCompanyKind } = useDetectCompanyWithKind({
    kind: AGENCY_TENANT
  })
  const { setOpenInterviewFeedbackDrawer, setInterviewDrawer } =
    useInterviewStore()
  const openFeedback = () => {
    setInterviewDrawer({
      id: String(interview?.id),
      returnUrl: location.href,
      openForm: false
    })
    setTimeout(() => {
      setOpenInterviewFeedbackDrawer(true)
    }, 0)
  }

  const isFeedbackDueInterview = interview?.fromDatetime
    ? isFeedbackDue({
        startTime: interview?.fromDatetime,
        hasFeedback: !!interview?.ikitFeedbacksSummary?.length
      })
    : false

  return (
    <div className="group relative flex w-full cursor-pointer flex-row items-center hover:cursor-default">
      <div className="flex min-w-[98px] flex-col border-r px-2">
        <TypographyText className="text-sm font-medium text-gray-700">
          {interview?.fromDatetime
            ? format(
                changeTimezone({
                  date: interview.fromDatetime,
                  timezone: user?.timezone
                }),
                'E, dd LLL'
              )
            : null}
        </TypographyText>
        <TypographyText className="mt-1 text-xs font-medium text-gray-600">
          {interview?.fromDatetime
            ? timeFormatDate(
                changeTimezone({
                  date: interview.fromDatetime,
                  timezone: user?.timezone
                })
              )
            : null}{' '}
          -{' '}
          {interview?.toDatetime
            ? timeFormatDate(
                changeTimezone({
                  date: interview.toDatetime,
                  timezone: user?.timezone
                })
              )
            : null}{' '}
        </TypographyText>
      </div>
      <div className="flex-1 px-2">
        <TypographyText className="flex text-sm font-medium text-gray-900">
          <Popover
            open={openDetail}
            onOpenChange={(value) => setOpenDetail(value)}>
            <PopoverTrigger asChild>
              <div className="line-clamp-1 flex-none hover:cursor-pointer hover:underline">
                {interview?.eventTypeDescription}
              </div>
            </PopoverTrigger>
            <PopoverPortal>
              <PopoverContent
                align="start"
                sideOffset={10}
                className="w-[400px] p-1">
                <CalendarPopupDetail
                  interviewId={Number(interview?.id)}
                  isCalendarView={false}
                  eventInfo={undefined}
                  closeEvent={() => setOpenDetail(false)}
                  refetchCalendar={(previousTypeAction) => {
                    interviewsListControl?.refetch(previousTypeAction)
                    setOpenDetail(false)
                  }}
                  updateInterview={action.interviewUpdateAction.updateInterview}
                  setEditModalInterview={setEditModal}
                />
              </PopoverContent>
            </PopoverPortal>
          </Popover>

          <div className="mx-1">-</div>
          <div className="line-clamp-1 hover:cursor-pointer hover:underline">
            <Tooltip content={interview?.profile?.fullName}>
              <Link
                href={configuration.path.candidates.detail(
                  interview?.profile?.id,
                  interview?.applicant?.id
                )}>
                {interview?.profile?.fullName}
              </Link>
            </Tooltip>
          </div>
        </TypographyText>
        <div className="mt-1 flex flex-row items-center space-x-2">
          {isCompanyKind ? (
            <div className="flex max-w-[50%] items-center space-x-1.5">
              <IconWrapper
                name="Building"
                size={14}
                className="flex-none text-gray-600"
              />
              <Tooltip content={interview?.job?.company?.name}>
                <TypographyText className="line-clamp-1 break-all text-sm text-gray-900">
                  {interview?.job?.company?.name}
                </TypographyText>
              </Tooltip>
            </div>
          ) : (
            interview?.location && (
              <div className="flex max-w-[50%] items-center space-x-1.5">
                <IconWrapper
                  name="MapPin"
                  size={14}
                  className="flex-none text-gray-600"
                />
                <Tooltip
                  content={Object.values(interview?.location || {})
                    .filter(
                      (locationValue) =>
                        !!locationValue &&
                        locationValue !== interview?.location?.id
                    )
                    .join(', ')}>
                  <TypographyText className="line-clamp-1 break-all text-sm text-gray-900">
                    {Object.values(interview?.location || {})
                      .filter(
                        (locationValue) =>
                          !!locationValue &&
                          locationValue !== interview?.location?.id
                      )
                      .join(', ')}
                  </TypographyText>
                </Tooltip>
              </div>
            )
          )}
          {(interview?.location || interview?.job?.company?.name) &&
            interview?.job && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2"
                height="2"
                viewBox="0 0 2 2"
                fill="none"
                className="flex-none">
                <circle cx="1" cy="1" r="1" fill="#9CA3AF" />
              </svg>
            )}
          {interview?.job && (
            <div className="flex max-w-[50%] items-center space-x-1.5">
              <IconWrapper
                name="Briefcase"
                size={14}
                className="flex-none text-gray-600"
              />
              <Tooltip content={interview?.job?.title}>
                <TypographyText className="line-clamp-1 break-all text-xs  text-gray-700">
                  {interview?.job?.title}
                </TypographyText>
              </Tooltip>
            </div>
          )}
        </div>
      </div>
      <If condition={isFeedbackDueInterview}>
        <Button
          onClick={openFeedback}
          size="xs"
          type="secondary"
          className="absolute right-0 top-0 mr-1 mt-1 hidden w-auto group-hover:flex">
          {t('button:feedback')}
        </Button>
      </If>
    </div>
  )
}

export default MyInterviewItemView
